import React, { useState } from "react"
import Layout from "../components/layout"

const notice = {
    success: '¡Formulario enviado correctamente!',
    danger: '¡Error al enviar el formulario!',
    legal: 'Política de privacidad aceptada'
}

const Contact = () => {
    const [state, setState] = useState(false)
    const [data, setData] = useState({ name: "", email: "", message: "", legal: "" })
    const { name, email, message, legal } = data;
    const [acceptsConsentCheckbox, setAcceptsConsentCheckbox] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...data })
        })
            .then(() => setState('success'))
            .catch(() => setState('danger'));
    }

    const saveData = (name, value) => {
        setData({ ...data, [name]: value })
    }

    const handleChange = e => {
        saveData(e.target.name, e.target.value)
    }

    const handleConsentCheckbox = e => {
        setAcceptsConsentCheckbox(e.target.checked);

        if (e.target.checked) {
            saveData(e.target.name, notice.legal)
        } else {
            saveData(e.target.name, '')
        }
    }

    return (
        <Layout title="Contacto">
            <div className="main-content post-main mt-3 p-3">
                <h1 className="heading mb-5">Contacto</h1>
                {
                    state && (
                        <div className={`alert alert-${state}`} role="alert">{notice[state]}</div>
                    )
                }
                <form name="contact" method="post" data-netlify="true" netlify-honeypot="awesome-field" onSubmit={handleSubmit}>
                    <input type="hidden" name="awesome-field" />
                    <input type="hidden" name="cianes-form" value="contact" />
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="name">Nombre</label>
                            <input id="name" type="text" name="name" className="form-control" value={name} onChange={handleChange} required />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="email">Correo</label>
                            <input id="email" type="email" name="email" className="form-control" value={email} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Mensaje</label>
                        <textarea id="message" name="message" className="form-control" value={message} onChange={handleChange} rows="5" required></textarea>
                    </div>
                    <div className="form-check ml-2">
                        <input id="legal" type="checkbox" name="legal" className="form-check-input" value={legal}
                            onChange={handleConsentCheckbox} required />
                        <label htmlFor="legal" className="form-check-label ml-2">Acepto la <a href="legal" rel="noopener noreferrer" target="_blank">política de privacidad</a></label>
                    </div>
                    {
                        name && email && message && acceptsConsentCheckbox && (
                            <button type="submit" className="btn btn-light btn-lg submit mt-3" disabled={!acceptsConsentCheckbox}>Enviar</button>
                        )
                    }
                </form>
                <div className="legal-notice"><ul>
                    <li><strong>Responsable datos:</strong> Pablo Cianes</li>
                    <li><strong>Finalidad:</strong> únicamente para poder contactar contigo para responderte</li>
                    <li><strong>Legitimación:</strong> tu consentimiento expreso aceptando la política de privacidad</li>
                    <li><strong>Almacenamiento datos:</strong> en el proveedor de correo electrónico</li>
                    <li><strong>Comunicación:</strong> No se comunicarán los datos a terceros salvo por obligación legal</li>
                    <li><strong>Derechos:</strong> Acceso, rectificación, portabilidad y olvido</li>
                </ul></div>
            </div>
        </Layout >
    )
}

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default Contact

